<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banner from "@/components/widgets/banner";
import Stat from "@/components/widgets/stat";
import ContractChart from "@/components/widgets/chart-contract-all";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Plan': 'Plano',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Plan': 'Plano',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banner,
    Stat,
    ContractChart
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        profit: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: [],

      statData: null,

      profitability: {
        loading: true,
        year: '2024',
        list: [],
      },

      simulate: {
        year: '2024',
      },
    };
  },
  methods: {
    getTotalProfit() {
      api
        .get('report/profit/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.profit = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.bonus = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = response.data.balance
            this.getStatData();
          }
        })
    },
    getStatData() {
      if (this.account.user.package.access == 'limited') {
        this.statData = [
          {
            type: 'center',
            icon: 'package',
            title: 'Cliente',
            value: this.account.user.name,
          },
          {
            type: 'center',
            icon: 'profit',
            title: 'Rendimentos',
            value: this.total.profit,
          },
          {
            type: 'center',
            icon: 'balance',
            title: 'Current Balance',
            value: this.total.balance,
          }
        ]
      } else {
        this.statData = [
          {
            type: 'center',
            icon: "package",
            title: "Plan",
            value: this.account.user.package.name,
          },
          {
            type: 'center',
            icon: 'profit',
            title: 'Rendimentos',
            value: this.total.profit,
          },
          {
            type: 'center',
            icon: 'bonus',
            title: 'Bonificações',
            value: this.total.bonus,
          },
          {
            type: 'center',
            icon: 'balance',
            title: 'Current Balance',
            value: this.total.balance,
          }
        ]
      }
    },
    getProfitability() {
      this.profitability.loading = true
      this.profitability.list = []
      this.profitability.accumulated = 0
      this.profitability.average = 0

      api
        .get('contract/profitability?year=' + this.profitability.year)
        .then((response) => {
          if (response.data.status == 'success') {
            this.profitability.list = response.data.list
          }

          this.profitability.loading = false
        })
    },
    simulateProfit() {
      if (this.profitability.year != this.simulate.year) {
        this.profitability.year = this.simulate.year
        this.getProfitability()
      }
    }
  },
  mounted() {
    this.getTotalProfit()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getProfitability()
  }
};
</script>

<template>
  <Layout>
    <Banner></Banner>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" :class="account.user.package.access == 'limited' ? 'col-md-4' : 'col-md-6 col-lg-6 col-xl-3'">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-center mb-3">
          <div class="px-2">
            <input class="form-control" v-model="simulate.year" type="number" min="2020" max="2024" step="1" value="2024" />
          </div>
          <div>
            <b-button type="submit" variant="default" v-on:click="simulateProfit()">
              {{ t('Filtrar') }}
            </b-button>
          </div>
        </div>
        <hr class="mt-1">

        <ContractChart :year="profitability.year" />

        <div v-if="profitability.loading" class="text-center">
          <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
        </div>
        <div v-else-if="profitability.list" class="table-responsive mt-3">
          <table class="table-profit table table-sm table-bordered text-center">
            <thead>
              <tr class="table-month">
                <th></th>
                <th v-for="mon in profitability.list[0].list" :key="mon">{{ mon.month }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ass, index) in profitability.list" :key="index">
                <td class="align-middle text-left">{{ ass.name }}</td>
                <td v-for="mon in ass.list" :key="mon.id">
                  <div v-if="mon.percent > 0" class="text-success">
                    {{ mon.percent | percent }}
                    <template v-if="index == 0">
                      <small><br>{{ mon.bonus | currency }}</small>
                    </template>
                  </div>
                  <div v-else-if="mon.percent < 0" class="text-danger">
                    {{ mon.percent | percent }}
                    <template v-if="index == 0">
                      <small><br>{{ mon.bonus | currency }}</small>
                    </template>
                  </div>
                  <div v-else-if="mon.percent == 0">
                    {{ mon.percent | percent }}
                    <template v-if="index == 0">
                      <small><br>{{ mon.bonus | currency }}</small>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center p-3">
          Nenhuma informação disponível neste período.
        </div>
      </div>
    </div>

    <div class="row" v-if="this.account.user.package.access == 'full'">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t('Referral link') }}
            </h5>
            <p class="m-0 notranslate">
              https://app.designogold.io/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://app.designogold.io/' + account.user.username"><i class="bx bx-copy font-size-18 text-dark"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #403219;
}
.contract-code {
  line-height: 14px;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #BEBFC1;
}
.table-month th {
  background: #D2D3D4;
}
</style>