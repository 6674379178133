<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  props: {
    year: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      empty: true,
      chartdata: { },
      options: { }
    }
  },
  methods: {
    getChart() {
      api
        .get('contract/chart?year=' + this.year)
        .then(response => {
          if (response.data.status=='success') {
            this.chartdata = {
              labels: response.data.labels,
              datasets: response.data.datasets
            }
            this.options = {
              defaultFontColor: '#403219',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: 20,
                      min: -20,
                      stepSize: 2,
                    },
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
  watch: {
    'year': function () {
      this.empty = true
      this.getChart();
    }
  }
}
</script>

<template>
  <div v-if="empty" class="text-center">
  </div>
  <div v-else>
    <Chart :chartdata="chartdata" :options="options"></Chart>
  </div>
</template>